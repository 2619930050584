.panel-table .table {
    margin-bottom: 0px;
}

.table tr td:last-child,
.table tr th:last-child {
    border-right: none;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border: none;
    border-right: 1px solid #dbe1e3;
    padding: 0px 20px 0px 20px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
    height: 56px;
    background-color: #f4fbfd;
    line-height: 56px;
    font-size: 1.1em;
    font-weight: 400;
}

.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    height: 45px;
    line-height: 45px;
    font-weight: 300;
    vertical-align: middle;
}
.table > tbody > tr:last-child {
    border: none;
}

.table.table-pointer > thead > tr > td,
.table.table-pointer > tbody > tr > td,
.table.table-pointer > tfoot > tr > td {
    cursor: pointer;
}

.table > thead > tr,
.table > tbody > tr,
.table > tfoot > tr {
    border-bottom: 1px solid #d9dcdd;
}

.table-hover > tbody > tr:hover {
    background-color: #f4fbfd;
}

.table-background {
    background: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.table > thead > tr > th > a {
    color: #000000;
}