.dropdown-menu {
    padding: 0px 0px 0px 0px;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
    padding: 0px 0px 0px 0px;
}

.bs-searchbox .form-control {
    border: none;
    box-shadow: none;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-bottom-left-radius: 0px;
    font-weight: 300;
    color: #000000;
}

.bootstrap-select.btn-group .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.dropdown-menu > li > a {
    height: 50px;
    line-height: 50px;
    padding: 0px 20px 0px 20px;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-color: #f4fbfd;
    box-shadow: none;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
    background-color: #f4fbfd;
}

.btn-default:hover {
    background-color: #f4fbfd;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-color: #f4fbfd;
}