.nav-tabs {
    background: #ffffff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    height:60px;
    margin-bottom: 10px;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    border: none;
    border-bottom: 2px solid #29aae2;
    opacity: 1.0;
}
.nav-tabs > li > a {
    border: none;
    line-height: 59px;
    height:59px;
    margin-right: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    padding: 0px 30px 0px 30px;
    font-size: 1.2em;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: #000000;
    opacity: 0.4;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background: none;
    opacity: 1.0;
}