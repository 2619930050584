.form-control {
    height: 50px;
    box-shadow: none;
    border: solid 1px rgba(0, 0, 0, 0.1);
}

.form-horizontal .form-group {
    margin-bottom: 10px;
}

.form-horizontal .form-group:last-child {
    margin-bottom: 0px;
}

.form-horizontal > .form-group > div > .bootstrap-select > .btn-default,
.panel-body > .bootstrap-select > .btn-default,
.form-horizontal .col-md-6 .form-group .btn-default,
.form-horizontal .col-md-12 .form-group .btn-default {
    height: 50px;
    border-color: rgba(0, 0, 0, 0.1);
}

.bootstrap-select.btn-group.disabled, .bootstrap-select.btn-group > .disabled {
    background-color: #eeeeee;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    color: #000000;
}

.form-horizontal .col-md-6 {
    padding: 0px 0px 0px 0px;
}

.form-horizontal .col-md-6 label,
.form-horizontal .col-md-12 label {
    opacity: 0.6;
    line-height: 16px;
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 8px;
}

.form-horizontal > .col-md-6 {
    margin-top: 20px;
    margin-bottom: 0px;
}

.form-horizontal.panel-body {
    padding-bottom: 40px;
}

.form-horizontal > .panel-block > .col-md-6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-horizontal > .panel-block {
    padding-left: 10px;
    padding-right: 10px;
}

.form-horizontal > .panel-block > .col-md-12 {
    padding: 0px 0px 0px 0px;
}

.form-horizontal.panel-body > .col-md-12 {
    padding: 20px 20px 0px 20px;
}

.form-horizontal.panel-body > .col-md-12 > .form-group {
    margin-right: 0px;
    margin-left: 0px;
}

.form-horizontal > .panel-block > .col-md-12 > .form-group {
    padding: 0px 20px 0px 20px;
    margin: 10px 0px 10px 0px;
}

.form-horizontal .col-md-6.odd .form-group {
    margin: 0px 5px 20px 20px;
}

.form-horizontal .col-md-6.even .form-group {
    margin: 0px 20px 20px 5px;
}

.form-horizontal .col-md-6 .form-group:last-child {
    margin-bottom: 0px;
}

.input-group > .input-group-addon {
    background-color: #ffffff;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.1);
    padding-right: 0px;
}

.input-group > .form-control {
    border-left: none;
}

.form-horizontal > .form-group.has-error > div > .bootstrap-select > .btn-default,
.form-horizontal .col-md-6 .form-group.has-error .btn-default,
.form-horizontal .col-md-12 .form-group.has-error .btn-default {
    border-color: #b94a48;
}

.input-group.has-error > .input-group-addon {
    border-color: #b94a48;
    background-color: #ffffff;
    color: #000000;
}

.input-group.has-error > .form-control {
    box-shadow: none;
}

.input-group {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.input-group > .form-control.first-in-group {
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    border-right: none;
}

.input-group > .input-group-addon.middle-in-group {
    padding-right: 12px;
    border-left: none;
    border-right: none;
}

.input-group .form-control:not(:first-child):not(:last-child) {
    -webkit-border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    margin-top: 0px;
    line-height: 59px;
}

.form-horizontal .hr-line-dashed {
    float: left;
    width: 100%;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    margin: 0px -20px 20px -20px;
    padding: 40px 20px 0px 20px;
}

.form-horizontal h3 {
    padding: 0px 20px 0px 20px;
    opacity: 0.8;
    float: left;
}

.ms-container {
    width: 100%;
    padding: 0px 20px 0px 20px;
}

.ms-container .ms-selectable, .ms-container .ms-selection {
    width: 48%;
}

.ms-container .ms-list {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(0, 0, 0, 0.05);
    min-height: 200px;
    height: auto;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
    height: 50px;
    line-height: 50px;
    padding: 0px 20px 0px 20px;
    color: #000000;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
    background-color: #f4fbfd;
    color: #000000;
}

.custom-header {
    height: 50px;
    line-height: 50px;
    font-size: 1.2em;
    color: #000000;
}

.form-horizontal .datepicker {
    padding: 6px 12px 6px 12px;
}

.datepicker-dropdown {
    z-index: 10000;
}

.bootstrap-select.btn-group .dropdown-menu {
    z-index: 10000;
}

.col-md-6 .btn-primary {
    width: 100%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    height: 60px;
    line-height: 60px;
    padding: 0px 0px 0px 0px;
    background-color: #29aae2;
    border: none;
    font-size: 1.3em;
    font-weight: 300;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.20);
    margin-top: 20px;
}

.checkButton {
    width: 100%;
    height: 60px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0px auto 10px auto;
}

.checkButton:hover {
    background-color: #f4fbfd;
    cursor: pointer;
}

.checkButton label {
    width: 100%;
    height: 100%;
}

.checkButton label span {
    padding-left: 20px;
    text-align: left;
    display: block;
    width: 100%;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0.3px;
    color: #000000;
    line-height: 60px;
}

.checkButton label input {
    display: none;
}

.checkButton input:checked + span {
    width: 100%;
    height: 60px;
    border-radius: 4px;
    background-color: #29aae2;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    color: #ffffff;
}

.glyphicon-ok {
    font-weight: 500;
    float: right;
    line-height: 60px;
    font-size: 17px;
    color: #ffffff;
    margin-right: 20px;
}

form > .centered p {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #000000;
    padding: 15px 0px 15px 0px;
}

.form-horizontal {
    margin: 0 auto;
}

.btn-primary {
    width: 345px;
    height: 60px;
    padding: 0px;
}