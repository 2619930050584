.navbar {
    height: 44px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: #0c3e69;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.navbar-nav > li > a {
    line-height: 45px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff;
}

.navbar-default .navbar-nav > li > a {
    color: #ffffff;
}

.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #ffffff;
    opacity: 1.0;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #ffffff;
    background: none;
}

.navbar-brand {
    height: 80px;
    line-height: 80px;
    padding: 0px 68px 0px 68px;
    background-color: #104873;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 1.4em;
}

.navbar-default .navbar-brand {
    color: #ffffff;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #ffffff;
    background-color: #104873;
}

.navbar-right .navbar-profile {
    display: block;
    margin-top: 17px;
    margin-right: 10px;
    height: 46px;
    width: 46px;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    border-radius: 23px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-position: center center;
    background-size: cover;
}

.navbar-right .icon {
    vertical-align: middle;
}

.navbar-right .icon-settings {
    font-size: 20px;
}

.navbar-right .icon-arrowDown {
    font-size: 6px;
    line-height: 80px;
}

.navbar-right > li {
    margin: 0px 0px 0px 0px;
}

.navbar-right > li:last-child {
    margin: 0px 30px 0px 20px;
}

.navbar-right > li > a {
    opacity: 1.0;
    padding: 0px 0px 0px 0px;
}

.navbar-right .dropdown-menu-right {
    width: 220px;
}

.navbar-right .mask {
    display: block;
    background: #ffffff;
    width: 38px;
    height: 38px;
    line-height: 38px;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    border-radius: 19px;
    margin: 21px 0px 21px 0px;
    text-align: center;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.navbar-right .mask .icon-settings {
    color: #29aae2;
    vertical-align: middle;
    font-size: 19px;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #ffffff;
    background: none;
}

.navbar .task-count {
    float: right;
    display: block;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    text-align: center;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background-color: #eb2f5e;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    margin: -8px -8px 0px 0px;
    padding: 0px 3px 0px 3px;
}

.navbar .dropdown-menu .task-count {
    margin: 14px 0px 0px 0px;
    color: #ffffff;
    box-shadow: none;
}

.navbar-default .icon-largeArrowLeft {
    font-size: 14px;
    vertical-align: middle;
    color: #ffffff;
    line-height: 45px;
    display: inline;
}

.navbar-default > .container-fluid > .navbar-inline > p,
.navbar-default > .container-fluid > p {
    line-height: 45px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff;
    display: block;
}

.navbar-nav {
    width: 100%;
}

.navbar-inline {
    margin-top: -45px;
}

.navbar-default .glyphicon-menu-hamburger {
    font-size: 20px;
    vertical-align: middle;
    color: #ffffff;
    line-height: 45px;
    display: inline;
    font-weight: lighter;
}

.glyphicon-remove {
    font-size: 18px;
    vertical-align: middle;
    color: #ffffff;
    margin: 30px 30px 0px 0px;
    display: inline;
    font-weight: lighter;
    text-align: right;
    float: right;
}

.navbar-right-link {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 20px;
}

.navbar-right-link .glyphicon {
    color: #ffffff;
}